import React, {useState} from 'react';
import Table from "../component/big/Table";
import {Helmet} from "react-helmet";

const Accountancy = () => {
    const [column, setColumn] = useState(['ردیف', 'نام', 'پرداخت', 'دستمزد']);
    const [data, setData] = useState([
        ['1', ' برای تست ادامه متن را مینویسم تا تست شودبیعانیه', 'پرداخت', 'profile/workspace/work-desk-pay/423423/', ' تومان 34543'],
        ['2', 'دیزاین', 'پرداخت', 'profile/workspace/work-desk-pay/43423/', ' تومان 34545343']
    ]);

    return (
        <>
            <Helmet>
                <title>سوالق مالی کاربر | PicoDevGit</title>
            </Helmet>
            <div className={'contain'}>
                <span className={'text vazir-bold active-color'}>سوابق مالی:</span>&nbsp;&nbsp;
                <br/>
                <br/>
                امکان پرداخت درحال حاضر وجود ندارد
                {/*<Table column={column} data={data}/>*/}
            </div>
            <div className={'fake'}></div>
        </>
    )
}

export default Accountancy;