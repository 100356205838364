import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import Header from '../component/big/Header';
import axios from 'axios';
import { link } from '../link';
import Config from '../server/Config';
import $ from 'jquery';

const TutorialLayout = () => {
    const { category, id } = useParams();
    const [titles, setTitles] = useState([]);

    useEffect(() => {
        const fetchLessonList = async () => {
            try {
                const config = Config(true);
                const response = await axios.get(`${link}/en/tutorial/tutorial-lesson-list/${category}/`, config);

                if (Array.isArray(response.data) && response.data.length > 0) {
                    setTitles(response.data);
                } else {
                    console.error("Response data is not an array or is empty:");
                }
            } catch (error) {
                console.error('Error fetching lesson list:', error);
            }
        };

        fetchLessonList();
    }, [category, id]);

    // show or hide list
    function showList() {
        $('.tutorial-list-container').css('display', 'block');
    }

    function hideList() {
        $('.tutorial-list-container').css('display', 'none');
    }

    // Pre-process titles to organize by category
    const processedTitles = [];
    let lastCategory = null;

    titles.forEach((title) => {
        if (title.category.category_title !== lastCategory) {
            processedTitles.push({
                type: 'category',
                title: title.category.category_title,
            });
            lastCategory = title.category.category_title;
        }
        processedTitles.push({
            type: 'tutorial',
            ...title,
        });
    });

    return (
        <>
            <Header />
            <div className="rtl">
                {/* list-shower */}
                <div className={'list-shower icon dark-box activate-back'} onClick={showList}>
                    <i className={'fa fa-bars'} id={'list-category-btn'}></i>
                </div>

                {/* tutorial-list */}
                <div className="w-15-tutorial tutorial-list-container dark-box">
                    <ul className="tutorial-list text">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <li className={'tutorial-list-title text-right list-times-icon'} onClick={hideList}>
                            <i className={'fa fa-times icon white-color activate-color'}></i>
                        </li>

                        {processedTitles.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.type === 'category' ? (
                                    <li className="tutorial-list-title">
                                        <h2 className="vazir-bold tutorial-list-h2 active-color">{item.title}</h2>
                                    </li>
                                ) : (
                                    <li
                                        className={`tutorial-list-item ${item.id === parseInt(id) ? 'active-back' : ''}`}
                                        key={item.id}
                                    >
                                        <Link
                                            className="tutorial-list-link white-color"
                                            to={`/fa/tutorial/${category}/${item.slug}/${item.id}/`}
                                        >
                                            {item.title}
                                        </Link>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}

                        <div className="fake">&nbsp;</div>
                        <div className="fake">&nbsp;</div>
                    </ul>
                </div>

                <Outlet />
            </div>
        </>
    );
};

export default TutorialLayout;
