import React, {useState} from 'react';
import Input from "../component/little/Input";
import {Link} from "react-router-dom";
import axios from 'axios';
import {link} from '../link';
import {Helmet} from "react-helmet";

const EnRegister = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    async function register(e) {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:8000/account/register/', {
                username: username,
                password: password
            });
            console.log('Registered in successfully');
            console.log(response.data);
            window.location.assign('/authentication/')
        } catch (error) {
            console.log('Invalid username or password');
        }
    }

    return (
        <>
            <Helmet>
                <title>Register | PicoDevGit</title>
            </Helmet>
            <div>
                <div className={'fake'}></div>
                <h1 className={'text-center'}>ثبت نام در پای تخته</h1>
                <div className={'fake'}></div>

                <div className={'d-flex flex-column align-items-center'}>
                    <Input placeholder={'نام کاربری'} id={'username'} func={setUsername}/>
                </div>

                <div className={'d-flex flex-column align-items-center'}>
                    <Input placeholder={'رمز دلخواه'} id={'password'} func={setPassword}/>
                </div>

                <div className={'d-flex flex-column align-items-center'}>
                    <div className={'col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3'}>
                        <button className={'btn active-back w-100 activate-back text'} onClick={register}>ثبت نام
                        </button>
                    </div>
                </div>

                <br/>
                <br/>
                <div className={'d-flex flex-column align-items-center'}>
                    <div className={'col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-between'}>
                        <Link className={'activate-color'} to={'/authentication/'}>
                            ورود
                        </Link>
                        <Link className={'activate-color'} to={'/authentication/email-forget/'}>
                            فراموشی رمز
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnRegister;
