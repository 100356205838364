import React, {useState} from 'react';
import Button from '../component/little/Button';
import axios from 'axios';
import Config from '../server/Config';
import {link} from '../link';
import {Helmet} from "react-helmet";


const Input = ({value, onChange, placeholder, name}) => {
    const [username, setUsername] = useState(value);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setUsername(newValue);
        // Call the parent component's onChange with the new username value
        onChange(newValue);
    };

    return (
        <div className="form-item">
            <Helmet>
                <title>ویرایش پروفایل | لنسرچت</title>
            </Helmet>
            <input
                name={name}
                className="dark-box activate-border vazir"
                autoComplete="off"
                id="username"
                value={username}
                onChange={handleChange}
            />
            <label htmlFor="username" className="vazir text rtl">
                {placeholder}
            </label>
        </div>
    );
};


const EditProfile = () => {
    const [userForm, setUserForm] = useState({
        username: '',
        firstName: '',
        lastName: '',
        number: '',
        description: '',
    });

    const handleChange = (name, value) => {
        setUserForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            const config = Config(false);

            for (let key in userForm) {
                formData.append(key, userForm[key]);
            }
            await axios.put(`${link}/account/edit-profile/`, formData, config);
            console.log('Form submitted successfully!');
            window.location.reload();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="contain">
            <div className="">
                <h1>ویرایش پروفایل:</h1>
                <div className="">
                    <br/>
                    <form className="row" onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="pay-box">
                            {/* Render the UsernameInput component */}
                            <Input
                                value={userForm.username}
                                onChange={(value) => handleChange('username', value)}
                                placeholder={'نام کاربری'}
                                name={'username'}
                            />
                        </div>
                        <div className="pay-box">
                            <Input
                                value={userForm.firstName}
                                onChange={(value) => handleChange('firstName', value)}
                                placeholder={'نام'}
                                name={'firstName'}
                            />
                        </div>
                        <div className="pay-box">
                            <Input
                                value={userForm.lastName}
                                onChange={(value) => handleChange('lastName', value)}
                                placeholder={'نام خانوادگی'}
                                name={'lastName'}
                            />
                        </div>
                        <div className="pay-box">
                            <Input
                                value={userForm.number}
                                onChange={(value) => handleChange('number', value)}
                                placeholder={'شماره تماس'}
                                name={'number'}
                            />
                        </div>
                        <div className="pay-box">
                            <Input
                                value={userForm.description}
                                onChange={(value) => handleChange('description', value)}
                                placeholder={'درباره ی من'}
                                name={'description'}
                            />
                        </div>

                        <hr/>
                        <div className="contain">
                            <br/>
                            <br/>
                            <Button text="ثبت تغییرات" type="submit"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
