import React from 'react';
import Input from "../component/little/Input";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const EmailForget = () => {
    return (
        <>

            <Helmet>
                <title>Email Forget | PicoDevGit</title>
            </Helmet>

            <div>
                <div className={'fake'}></div>
                <h1 className={'text-center'}>ایمیل وریفای</h1>
                <div className={'fake'}></div>

                <div className={'d-flex flex-column align-items-center'}>
                    <Input placeholder={'ایمیل'} id={'email'}/>
                </div>

                <div className={'d-flex flex-column align-items-center'}>
                    <div className={'col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3'}>
                        <button className={'btn active-back w-100 activate-back text'}>ارسال لینک ورود</button>
                    </div>
                </div>

                <br/>
                <br/>
                <div className={'d-flex flex-column align-items-center'}>
                    <div className={'col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-between'}>
                        <Link className={'activate-color'} to={'/authentication/'}>
                            ورود
                        </Link>
                        <Link className={'activate-color'} to={'/authentication/register/'}>
                            ثبت نام
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default EmailForget;