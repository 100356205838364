import React from "react";
import {Link} from 'react-router-dom';

const Category = (props) => {
    return (
        <>
            <div className={'col-sm-12 col-xs-12 col-md-12 col-lg-4 col-xl-3 text-center category-block'}>
                <div className={'category-container activate-color dark-box'}>
                    <img className={'category-img'} src={props.photo}/>
                    <Link to={props.path} className={'dark-box'} state={props.state}>
                        <h4 className={'text category-title activate-color'}>{props.title}</h4>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Category;