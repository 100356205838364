import React from "react";
import {useState} from 'react';

const Input = (props) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };
    return (
        <div className="form-item">
            <input
                name={props.name}
                className={'dark-box activate-border vazir'}
                id={props.id}
                autoComplete="off"
                type={props.type || 'text'}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={props.value}
                onChange={(e) => props.func(e.target.value)}
            />
            <label htmlFor={props.id}
                   className={isFocused ? 'active-color dark-box vazir text rtl' : 'vazir text rtl'}>
                {props.placeholder}
            </label>
        </div>
    )
}

export default Input;