import React, {useEffect, useState} from 'react';
import Input from '../component/little/Input';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {link} from '../link';
import {openDB} from 'idb';
import Config from '../server/Config';
import {Helmet} from "react-helmet";


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Function to save accessToken to IndexedDB
    async function saveAccessTokenToIndexedDB(token) {
        const db = await openDB('my-db', 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains('tokens')) {
                    db.createObjectStore('tokens');
                }
            },
        });

        const tx = db.transaction('tokens', 'readwrite');
        const store = tx.objectStore('tokens');

        store.put(token, 'accessToken'); // Save the token with key 'accessToken'

        await tx.done;
    }

    async function login(e) {
        e.preventDefault();
        try {
            console.log('testing');
            const formData = new FormData();
            formData.append('username', username); // Append username to form data
            formData.append('password', password); // Append password to form data

            console.log(link);
            const config = Config(true);

            const response = await axios.post(`${link}/account/login/`, formData, config);

            // Save JWT tokens to local storage
            localStorage.setItem('refreshToken', response.data.refresh);
            localStorage.setItem('accessToken', response.data.access);

            // Save JWT tokens to IndexedDB
            await saveAccessTokenToIndexedDB(response.data.access);

            console.log('Logged in successfully');
            console.log(response.data.refresh);

            // go to another page
            window.location.assign('/');
        } catch (error) {
            console.error('Login error:', error);
        }
    }


    return (
        <>
            <Helmet>
                <title>Login | PicoDevGit</title>
            </Helmet>
            <div>
                <div className={'fake'}></div>
                <h1 className={'text-center'}>ورود به پای تخته</h1>
                <div className={'fake'}></div>

                <div className={'d-flex flex-column align-items-center'}>
                    <Input placeholder={'نام کاربری'} id={'email'} func={setUsername}/>
                </div>

                <div className={'d-flex flex-column align-items-center'}>
                    <Input placeholder={'رمز ورود'} id={'password'} func={setPassword}/>
                </div>

                <div className={'d-flex flex-column align-items-center'}>
                    <div className={'col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3'}>
                        <button className={'btn active-back w-100 activate-back text'} onClick={login}>ورود</button>
                    </div>
                </div>

                <br/>
                <br/>
                <div className={'d-flex flex-column align-items-center'}>
                    <div className={'col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-between'}>
                        <Link className={'activate-color'} to={'/authentication/register/'}>
                            ثبت نام
                        </Link>
                        <Link className={'activate-color'} to={'/authentication/email-forget/'}>
                            فراموشی رمز
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Login;