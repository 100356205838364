import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {link} from '../../link';
import Config from '../../server/Config';

const EnCategory = () => {
    const [service, setService] = useState([
        {
            service_name: 'bale',
            link: '#',
            cover: '',
        }
    ]);

    const [category, setCategory] = useState([
        {
            id: 1,
            category_title: 'title',
            category_slug: 'slug',
            first_tutorial: {
                slug: 'tutorial-slug',
            },
            first_lesson: 1,
            cover: '',
        }
    ])

    useEffect(() => {
        const getService = async () => {
            try {
                const config = Config(true);
                const response = await axios.get(`${link}/set/service/`, config);
                if (response.status === 200) {
                    setService(response.data);
                    console.log('this is service: ', response.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getService();
        const getCategory = async () => {
            try {
                const config = Config(true);
                const response = await axios.get(`${link}/set/category/`, config);
                if (response.status === 200) {
                    console.log(response.data);
                    setCategory(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getCategory();
    }, []);

    return (
        <>
            <div className={'text-center'}>
                <h2>PicoDevGit Services</h2>
                <br/>
            </div>
            <div className={'row contain ltr'}>
                {service.map(s => (
                    // block of category code
                    <div className={'col-sm-4 col-md-4 col-lg-3 col-xl-3 text-center category-block'}>
                        <div className={'category-container activate-color dark-box'}>
                            <a href={s.link} target={'_blank'}>
                                <img className={'category-img'} src={s.cover} alt={`photo to title ${s.service_name}`}/>
                                <div className={'dark-box'}>
                                    <h4 className={'text category-title activate-color'}>{s.service_name}</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className={'fake'}></div>
            <div className={'fake'}>&nbsp;</div>

            {/* parallax service section */}
            <div className={'text-center'}>
                <h2>PicoDevGit Tutorials</h2>
                <br/>
            </div>
            <div className={'parallax-container'}>
                <div className={'parallax row contain ltr'}>
                    {category.map(c => (
                        // block of category code
                        <div className={'col-sm-4 col-md-4 col-lg-3 col-xl-3 text-center category-block'}>
                            <div className={'category-container activate-color dark-box'}>
                                <Link to={`/fa/tutorial/${c.category_slug}/${c.first_tutorial.slug}/${c.id}/`}
                                      target={'_blank'}>
                                    <img className={'category-img'} src={c.cover}
                                         alt={`photo to title ${c.category_title}`}/>
                                    <div className={'dark-box'}>
                                        <h4 className={'text category-title activate-color'}>{c.category_title}</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className={'parallax-gradient d-flex flex-column align-items-center justify-content-end dark-gradient'}>
                    <Link to={'/en/tutorial-search/'} className={'btn activate-back active-back'}>Show More</Link>
                </div>
            </div>
            <div className={'fake'}></div>
        </>
    )
}

export default EnCategory;