import Category from "../component/big/Category";
import COMPUTER from "../../files/assets/computer.gif";
import ARTDESIGN from "../../files/assets/art-design.gif";
import React from "react";

const ProfileCategory = () => {
    return (
        <>
            <div className={'row contain'}>
                <Category photo={COMPUTER} title={'ویرایش پروفایل'} path={'edit-profile/'}/>
                <Category photo={ARTDESIGN} title={'مالی'} path={'accountancy/'}/>
            </div>


        </>
    )
}

export default ProfileCategory;