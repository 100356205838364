import React from 'react';
import ME from "../../files/assets/me.jfif";
import FileConfig from '../server/FileConfig';
import Config from '../server/Config';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {link} from '../link';
import {Link} from 'react-router-dom';

const TopOfProfile = () => {
    const [user, setUser] = useState({});
    const [profile, setProfile] = useState();

    useEffect(() => {
        async function userInfo() {
            try {
                const config = await Config(false);

                const data = await axios.get(`${link}/account/all-user-info/`, config);

                await setUser(data.data);
                await setProfile(link + data.data.photo);
                console.log(data.data);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        }

        userInfo();


    }, []);

    const profileChooser = (id) => {
        let el = document.getElementById(id);

        el.addEventListener('change', async function (e) {
            // find form
            let myForm = document.querySelector('#myForm');

            // insert the photo and retrieve it
            let selectedFile = e.target.files[0];
            setProfile(URL.createObjectURL(selectedFile));

            if (!selectedFile) {
                console.log('No file selected');
                return;
            }

            // instead append a photo, append all form
            const formData = new FormData();
            formData.append('photo', document.getElementById(id).files[0]);
            console.log('This is formData:', formData);
            console.log('This is file:', document.getElementById(id).files[0]);

            try {
                const config = FileConfig(false);

                // config['body'] = {'profile': selectedFile};

                const response = await axios.put(`${link}/account/edit-profile-photo/`, formData, config);

                // Reset the form to clear the file input field
                let form = el.closest('form');
                if (form) {
                    form.reset();
                }

                if (response.status === 200) {
                    console.log('Updated successfully');
                } else {
                    console.log('Update failed with status:', response.status);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        });

        el.click();
    }

    return (
        <>
            <div className={'w-100'}>

                <div className={'row'}>
                    {/* top part */}
                    <div className={'contain col-sm-12 col-md-12 col-lg-6 col-xl-6'}>
                        <div className={'fake'}></div>
                        <div className={'profile-info'}>
                            <span className={'text vazir-bold active-color'}>نام:</span>&nbsp;&nbsp;
                            <span className={'text'}>{user.first_name} {user.last_name}</span>
                        </div>
                        <div className={'profile-info'}>
                            <span className={'text vazir-bold active-color'}>شماره:</span>&nbsp;&nbsp;
                            <span className={'text'}>{user.number}</span>
                        </div>
                        <div className={'profile-info'}>
                            <span className={'text vazir-bold active-color'}>درباره ی من:</span>&nbsp;&nbsp;
                            <p className={'text justify'}>
                                {user.bio}
                            </p>

                        </div>

                        <div className={'profile-info'}>
                            <Link to={'create-company/'} className={'btn active-back activate-back vazir-bold'}>ایجاد شرکت</Link>
                        </div>


                    </div>
                    {/* top part */}
                    <div className={'col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center'}>
                        <div className={'fake'}></div>

                        <form className={'d-none'} encType={'multipart/form-data'} id={'myForm'}>
                            <input className={''} type={'file'} name={'profile-chooser'} id={'profile-chooser'}/>
                        </form>

                        <img src={user !== {} ? `${profile}` : ME} className={'active-border profile-photo'}/>

                        &nbsp;
                        <span className={'btn active-back activate-back'}
                              onClick={(e) => profileChooser('profile-chooser')}>
                            <i className={'fa fa-pencil icon'}></i>
                        </span>

                    </div>

                </div>

            </div>

            <br/>
            <hr/>
            <br/>
        </>
    )
}

export default TopOfProfile;