import React from 'react';
import Input from "../component/little/Input";
import FirstPhoto from "../../files/assets/flower.jpg";
import Category from "../component/big/Category";
import COMPUTER from "../../files/assets/computer.gif";
import ARTDESIGN from "../../files/assets/art-design.gif";
import {useState, useEffect} from 'react';
import axios from 'axios';
import {link} from '../link';
import Config from '../server/Config';
import {Helmet} from "react-helmet";

const Search = () => {
    const [search, setSearch] = useState(
        [
            {
                id: '2',
                category_cover: 'data:image/gif;base64,R0lGODlhlgCWAMQSAM1gW4RryKhmkmhw8bpjduhbMnFu5N9dP9ZeTZ9nn3pt1pZorY1qu7FkhMNhaHTa//FaJF9x/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABIALAAAAACWAJYAAAX/oCSOZGmeaKqubOu+cCzPdG3feK7vfO//wKBwSCwaj8ikcslsOp/QqHRKrVqv2Kx2y+16v+CweEwum8/otHrNbrvf8Lh8Tq/b7/i8fl+M+P+AgYKDhIWGh4iJihFLi46PkJGSf0mTlpeYkZWZnJ2elEeEfIZIgnwlg6WApyamoausJK5GsLEjs32gtiK4RLW7vUO/tsFCw04PyU7FQcdNydBMzEDOTNDRStM/1UrX2Jvc27pP3srZgap+UuXS6K/qUeyN7rTjyN7t4T76RvLz/DwA3njAwt8Ic74E6lBI4xsKgyIcNmOIgyIMfCogYjRm0UbHFuUIPtx4EGIPerng//UIKfKESZYT7QmTiYNlSxMabVL7SIOnS5s3S+TUuc+nDKMlgQZNKjGi0pNIYURVihAnSRJUl9ZAmVBlw6wZr2IFWzHqC59kw15bkXarWRdon7IV+xNoWZoxGdUgCpLuSJg3uM70KgOwC5NzEcd4CxfvYcMF/f5VLNXxTsIx5KptmkKzW8yD9X71bHVtZNIzGLeIS7WzZKZ2A1sWB7pw69Kcx6LuObtobdukKTuNvbA31N8zgr/eLRs5bdE6PAsnHtB4deejr04PmRccdB7cYVfFnbs49uPfwZsWn7i8+fShhawf7n54Svgcz9uQKNxpPf07qNbXa+cA+F43BBaIX/93SfSnoHcIzheFgCtQOKBWy1gXoIY/YJihgTlYWIaIKJA4homtcFgHiqioSAeLsrg4B4y3yCgHjbzYGAeOEvDIBY8+bgGkjnDg+MmRSEqSTpJMNinKf05GKeU7UlbJJJRWZnnkfX4YAMCXYIYp5phklmnmmWimqSaaDJg4CAEQxCnnnHTWaeedeOap55586nmAm4EMUECfhBZq6KGIzrmAiIMIkOijkEZ6KAKCveeHoJJmqummcgZA4SAJcCrqqIgCUGlzgBxA6qqs6qmAaoMs0OqstMpJwKmpDaJqrbyyagCuiwkSQK/EknprVIQAUOyynA4AbGOBDMvstJIKoA3IC4TASe22iRbgrE+DGMDtuIgmcG0K2ZKrLqF/nntCuOvGy+eiz77bqLz44tluvS0GOmi+ANPpKb81CqKAAAgnrPDCDCscsJ0NRyzxwm26m2OSCDw8J6VTYttkAxrLaW7HFTZpwL8PH1tlyU4qkHG+BTgwcJYqWGlAADjnrPPOPPfs888/f6vlb0MXbXQiJR6t9NJ4Me200TU/LTXJu1Rt9dVYZ6311lx37fXXYIct9thkl2322WinrfbabLft9ttwxy333HTfEAIAOw==',
                category_title: 'hellohellohellohellohellohellohellohellohellohellohello',
                first_tutorial: {
                    slug: 'test-first',
                },
                category_slug: 'category-slug-example',
            },
        ]
    )

    useEffect(() => {
        async function articleList() {
            const config = Config(true);

            const articleList = await axios.get(`${link}/en/tutorial/tutorial-list/`, config);

            setSearch(articleList.data);
            console.log(articleList.data);
        }

        articleList();
    }, []);

    return (
        <>
            <Helmet>
                <title>لیست آموزش ها | PicoDevGit</title>
                <meta name="description" content={'Tutorials List of PicoDevGit website | PicoDevGit'}/>
                <meta name="keywords" content={'Tutorials List, Online Learn, free Learn, '}/>
                <meta property="og:description" content={'Tutorials List of PicoDevGit website | PicoDevGit'}/>
                <meta property="og:title" content={'Tutorial List | PicoDevGit'}/>
            </Helmet>
            <div className={'w-100'}>
                <div className={'fake'}></div>
                <div className={'text-center'}>
                    <h1>Search Tutorials</h1>
                </div>

                <div className={'row contain'}>
                    {search.map((s) =>
                        <Category photo={COMPUTER} title={s.category_title}
                                  state={s.id}
                                  path={`/en/tutorial/${s.category_slug}/${s.first_tutorial.slug}/${s.id}/`}/>
                    )}
                    <Category photo={COMPUTER} title={'بهترین روش های کاریابی در این وبسایت و راهنما'}
                              state={'1'}
                              path={'detail/بهترین-روش-های-کاریابی-در-این-وبسایت-و-راهنما/picodevgit/'}/>
                </div>

            </div>
        </>
    )
}

export default Search;