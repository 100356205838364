import Token from './Token';

const Config = (AllowAny) => {
    try {
        if (AllowAny === false) {
            if (Token === null) {
                if (window.location.pathname !== '/authentication/') {
                    window.location.assign('/authentication/');
                } else {
                    return { headers: { 'Content-Type': 'application/json' } };
                }
            } else {
                return {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Token}`,
                    },
                };
            }
        } else if (AllowAny === true) {
            if (Token === null) {
                return { headers: { 'Content-Type': 'application/json' } };
            } else {
                return {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Token}`,
                    },
                };
            }
        } else {
            console.log('entered with mistake !!!');
            return { headers: { 'Content-Type': 'application/json' } }; // Default headers in case of an error
        }
    } catch (err) {
        console.log(err);
        return { headers: { 'Content-Type': 'application/json' } }; // Default headers in case of an exception
    }
};

export default Config;
