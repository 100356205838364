import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// import Landing from './picodevgit/landing/Landing';
import Landing from './picodevgit/landing/Landing';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from './picodevgit/Layout';

// about
import About from './picodevgit/about/About';

// profile
import Profile from './picodevgit/profile/Profile';
import ProfileCategory from './picodevgit/profile/ProfileCategory';
import EditProfile from './picodevgit/profile/EditProfile';
import Accountancy from './picodevgit/profile/Accountancy';

// en tutorial
import TutorialLayout from './picodevgit/tutorial/TutorialLayout';
import Search from './picodevgit/tutorial/Search';
import Tutorial from './picodevgit/tutorial/Tutorial';

// en auth
import AuthLayout from './picodevgit/auth/AuthLayout';
import Login from './picodevgit/auth/Login';
import EmailForget from './picodevgit/auth/EmailForget';
import EnRegister from './picodevgit/auth/EnRegister';

import App from './Installing';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Landing/>}/>
                    <Route path={'install/'} element={<App/>}/>

                    {/* about */}
                    <Route path={'en/about/'} element={<About/>}/>

                    {/* fa profile */}
                    <Route path={'fa/profile/'} element={<Profile/>}>
                        <Route index element={<ProfileCategory/>}/>
                        <Route path={'edit-profile/'} element={<EditProfile/>}/>
                        <Route path={'accountancy/'} element={<Accountancy/>}/>
                    </Route>

                    {/* en profile */}
                    <Route path={'en/profile/'} element={<Profile/>}>
                        <Route index element={<ProfileCategory/>}/>
                        <Route path={'edit-profile/'} element={<EditProfile/>}/>
                        <Route path={'accountancy/'} element={<Accountancy/>}/>
                    </Route>

                    {/* tutorial */}
                    <Route path={'en/tutorial-search/'} element={<Search/>}/>

                    {/* en auth */}
                    <Route path={'en/authentication/'} element={<AuthLayout/>}>
                        <Route index element={<Login/>}/>
                        <Route path={'register/'} element={<EnRegister/>}/>
                        <Route path={'email-forget/'} element={<EmailForget/>}/>
                    </Route>

                </Route>

                {/* en tutorial */}
                <Route path="/en/tutorial/:category/:subject/:id/" element={<TutorialLayout/>}>
                    <Route index element={<Tutorial/>}/>
                </Route>

            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
