import React from 'react';
import TopPart from './en/TopPart.jsx';
import {useEffect, useState} from 'react';
import EnCategory from './en/EnCategory';

const Landing = () => {
    const [lang, setLang] = useState('');

    useEffect(function () {
        const language = localStorage.getItem('language');
        if (language === null) {
            setLang(navigator.language || navigator.userLanguage);
            localStorage.setItem('language', navigator.language || navigator.userLanguage);
        } else {
            setLang(language);
        }
    }, [lang]);

    return (
        <>
            {/* landing */}
            <div className={'w-100'}>
                <div className={'fake'}></div>
                {/* top part */}
                <TopPart/>

                <div className={'fake'}>
                    <br/>
                </div>

                <EnCategory/>

                <div className={'fake'}>
                    <br/>
                </div>
            </div>

        </>
    );
}

export default Landing;
