import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../server/Config';
import { link } from '../link';
import axios from 'axios';
import PHOTO from '../../files/assets/enamad.jpg';

const About = () => {
    const [about, setAbout] = useState({
        about_title: 'introduce picodevgit',
        about_content: 'this is about picodevgit',
        about_photo: PHOTO,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let config = Config(true);
                const aboutData = await axios.get(`${link}/set/about/`, config);
                if (aboutData.status === 200) {
                    setAbout(aboutData.data);
                    console.log(aboutData);
                } else {
                    console.log(aboutData.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title> about us | PicoDevGit</title>
            </Helmet>
            <div className="contain ltr">
                {/* top */}
                <div className="TopOfArticle">
                    <div className="fake"></div>

                    {/* top of article */}
                    <div className="w-100">
                        <img src={link + about.about_photo} className="article-cover" alt="introduce picodevgit photo"/>
                    </div>

                    <div className={'fake'}></div>

                    {/* title of page */}
                    <div>
                        <h1 className={''}>
                            {about.about_title}
                        </h1>
                    </div>

                    <div className="fake"></div>

                </div>
                <div className="fake"></div>

                {/* main part */}
                <div className="w-100">
                    <p className="text justify">
                        <div dangerouslySetInnerHTML={{__html: about.about_content}}></div>
                    </p>
                </div>
            </div>
        </>
    );
};

export default About;
