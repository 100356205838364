import axios from 'axios';
import Config from './Config';
import {link} from "../link";

const Identify = async (AllowAny) => {
    const config = Config(AllowAny);
    console.log(config);
    let info;
    if (config) {
        try {
            info = await axios.get(`${link}/account/identify/`, config);
            if (info.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    } else {
        return false;
    }
}

export default Identify;