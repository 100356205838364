import React from 'react';
import BaseFirst from "../../../files/assets/base-first.png";
import {Helmet} from 'react-helmet';

const TopPart = () => {
    return (
        <>
            <Helmet>
                <title>Tutorials and Freelance Job Finding Service | PicoDevGit</title>
            </Helmet>

            <section className={'row w-100 contain ltr first-section'}>
                <div
                    className={'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column align-items-center'}>
                    <div className={'fake'}></div>
                    <h1>PicoDevGit <span
                        className={'active-color'}>Tutorials and Freelance Job Finding Service</span> service</h1>
                    <br/>
                    <p className={'text justify'}>&nbsp;&nbsp;&nbsp;&nbsp;
                        PicoDevGit provides educational services in both English and Persian, offering video and text
                        content. This platform is designed to expedite freelancers' entry into the job market. After
                        learning, you can start finding suitable jobs on our website or channel.
                    </p>
                    <br/>

                    {/*<button className={'btn active-back bold activate-back'}>نصب رایگان لنسرچت</button>*/}
                    <div className={'fake'}></div>

                </div>

                <div
                    className={'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center'}>
                    <img src={BaseFirst} className={'first-photo'}/>
                </div>
            </section>
        </>
    )
}

export default TopPart;