import React, {useState, useEffect} from 'react'
import LOGO from '../../../files/assets/logo3.png';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Identify from '../../server/Identify';


const Header = () => {
    const [islight, setLight] = useState(false);
    const [small, setSmall] = useState(false);
    const [know, setKnow] = useState(false);

    useEffect(() => {
        try {

            const test = async () => {
                const info = await Identify(true);
                if (info) {
                    if (info) {
                        setKnow(true);
                    } else {
                        setKnow(false);
                    }
                } else {
                    console.log('is not authenticated');
                }
            }
            test();

        } catch (err) {
            console.log(err)
        }
    }, [])

    const toggleTheme = () => {
        console.log('come to change');

        if (islight === false) {
            let darkElements = Array.from(document.getElementsByClassName('dark'));
            let lightElements = Array.from(document.getElementsByClassName('dark-box'));
            let whiteColorElements = Array.from(document.getElementsByClassName('white-color'));
            let darkGradient = Array.from(document.getElementsByClassName('dark-gradient'));

            // Toggle dark to light theme
            darkElements.forEach(darkElement => {
                darkElement.classList.remove('dark');
                darkElement.classList.add('light');
            });

            lightElements.forEach(el => {
                el.classList.remove('dark-box');
                el.classList.add('light-box');
            });

            whiteColorElements.forEach(whiteColorElement => {
                whiteColorElement.classList.remove('white-color');
                whiteColorElement.classList.add('black-color');
            });

            darkGradient.forEach(el => {
                el.classList.remove('dark-gradient');
                el.classList.add('light-gradient');
            })

            setLight(true);
        } else {
            // Toggle light to dark theme
            let lightElements = Array.from(document.getElementsByClassName('light'));
            let lightBoxes = Array.from(document.getElementsByClassName('light-box'));
            let blackColorElements = Array.from(document.getElementsByClassName('black-color'));
            let lightGradient = Array.from(document.getElementsByClassName('light-gradient'));

            lightElements.forEach(lightElement => {
                lightElement.classList.remove('light');
                lightElement.classList.add('dark');
            });

            lightBoxes.forEach(el => {
                el.classList.remove('light-box');
                el.classList.add('dark-box');
            });

            blackColorElements.forEach(blackColorElement => {
                blackColorElement.classList.remove('black-color');
                blackColorElement.classList.add('white-color');
            });

            lightGradient.forEach(el => {
                el.classList.remove('light-gradient');
                el.classList.add('dark-gradient');
            })

            setLight(false);
        }
    }

    const toggleNav = () => {
        $('#small-nav-container').toggleClass('d-none');
        $('#small-nav-icon').toggleClass('fa-bars fa-times');
    };

    // change language
    function changeLanguage(e) {
        e.preventDefault();
        localStorage.setItem('language', 'fa-IR');
        window.location.reload();
    }

    return (<>
        <div className={'sticky ltr dark-box'}>
            <div className={'top-header dark vazir-bold'}>
                <img src={LOGO} alt="Logo"/>
            </div>
            <div className={'row contain'}>
                <div className={'col-4 col-sm-4 col-md-6 col-lg-9 col-xl-9'}>
                    <ul id={'big-nav'} className={'nav active-color text'}>
                        <Link to={'/'} className={'nav-item white-color activate-color'} target={'_self'}>Home</Link>
                        {/*<Link to={'/projects/'} className={'nav-item white-color activate-color'} target={'_self'}>پروژه*/}
                        {/*    ها</Link>*/}
                        <Link to={'/en/tutorial-search/'} className={'nav-item white-color activate-color'}
                              target={'_self'}>Tutorials</Link>
                        <Link to={'/en/about/'} className={'nav-item white-color activate-color'} target={'_self'}>About
                            us</Link>
                    </ul>
                    <div className={'nav align-items-center'} onClick={toggleNav} id={'small-nav'}>
                        <i className={'fa fa-bars nav-item icon activate-color'} id={'small-nav-icon'}></i>
                    </div>
                </div>

                <div className={'col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 ltr d-flex align-items-center'}>
                    {/* user icon */}
                    {know ?
                        <Link className={'ltr dark-box'} to={'en/profile/'}>
                            <i className={'fa fa-user-o icon activate-color nav-item'}></i>
                        </Link>
                        :
                        <Link className={'ltr dark-box'}
                              to={'en/authentication/'}>
                            <i className={'fa vazir text icon activate-color nav-item'}></i>
                        </Link>
                    }
                </div>

                {/* language mood */}
                <div id={'languageChanger'}
                     className={'col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1 ltr d-flex align-items-center'}>
                    <a className={'icon white-color'} target={'_blank'} href={'https://picodevgit.com/'}>
                        Fa
                    </a>
                </div>

                {/* theme mood */}
                <div className={'col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 ltr d-flex align-items-center'}>
                    <i id={'lightTheme'}
                       className={islight ? 'fa fa-sun-o icon activate-color nav-item d-none' : 'fa fa-sun-o icon activate-color nav-item'}
                       onClick={toggleTheme}></i>
                    <i id={'darkTheme'}
                       className={islight ? 'fa fa-moon-o icon activate-color nav-item' : 'fa fa-moon-o icon activate-color nav-item d-none'}
                       onClick={toggleTheme}></i>
                </div>

                <div id={'small-nav-container'}
                     className={'row small-nav-container d-none contain d-flex align-items-center justify-content-center'}>
                    <Link to={'/'} className={'small-nav-item white-color activate-color'}
                          target={'_self'}>Home</Link>
                    {/*<Link to={'/projects/'} className={'small-nav-item white-color activate-color'} target={'_self'}>پروژه*/}
                    {/*    ها</Link>*/}
                    <Link to={'/en/tutorial-search/'} className={'small-nav-item white-color activate-color'}
                          target={'_self'}>Tutorials</Link>
                    <Link to={'/en/about/'} className={'small-nav-item white-color activate-color'} target={'_self'}>About
                        us
                    </Link>
                </div>
            </div>


        </div>

        <div className={'fake'}>&nbsp;</div>
        <div className={'fake'}>&nbsp;</div>

    </>);
}

export default Header;