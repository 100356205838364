import Token from './Token';

const FileConfig = (AllowAny) => {
    try {
        // here check the parameter is false or true
        if (AllowAny === false) {
            // if token is null should go to login page
            if (Token === null) {
                if (window.location.pathname !== '/authentication/') {
                    window.location.assign('/authentication/');
                } else {
                    // if user is in the login page say false
                    return false;
                }
            } else {
                // everything is ok with token
                return {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${Token}`, // Include the token in the Authorization header
                    },
                };
            }
        } else if (AllowAny === true) {
            if (Token === null) {
                // return false without going to login page
                return false;
            } else {
                return {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${Token}`, // Include the token in the Authorization header
                    },
                };
            }
        } else {
            console.log('entered with mistake !!!')
        }
    } catch (err) {
        console.log(err);
    }
}

export default FileConfig;