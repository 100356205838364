import React from 'react';
import {Outlet, Link} from 'react-router-dom';
import TopOfProfile from './TopOfProfile';
import {Helmet} from 'react-helmet';

const Profile = () => {
    return (
        <>
            <Helmet>
                <title>پروفایل کاری | PicoDevGit</title>
            </Helmet>
            <TopOfProfile />
            <Outlet />
        </>
    )
}

export default Profile;