import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import {link} from '../link';
import Config from '../server/Config';
import {Helmet} from 'react-helmet';
import '../../files/bootstrap/bootstrap.rtl.min.css';
import '../../files/css/style.css';
import Footer from "../component/big/Footer";

const Tutorial = () => {
    const {category, id} = useParams();
    const [tutorial, setTutorial] = useState({
        title: '',
        author: '',
        content: '',
        description: '',
        keywords: '',
        cover: '',
        video: '',
        previous_tutorial: {
            id: 1,
            slug: 'example-slug',
        },
        next_tutorial: {
            id: 1,
            slug: 'example-slug',
        },
    });

    useEffect(() => {
        const getArticle = async () => {
            try {
                const config = Config(true);
                const tuto = await axios.get(`${link}/en/tutorial/tutorial-detail/${id}/`, config);
                setTutorial(tuto.data);
                console.log('Fetched tutorial:', tuto.data); // Debug log
            } catch (error) {
                console.log(error);
            }
        };

        getArticle();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>{`${tutorial.title} | PicoDevGit`}</title>
                <meta name="description" content={tutorial.description}/>
                <meta name="keywords" content={tutorial.keywords}/>
                <meta property="og:description" content={tutorial.description}/>
                <meta property="og:title" content={`${tutorial.title} | PicoDevGit`}/>
            </Helmet>
            <div className={'d-flex ltr'}>

                {/* fake column under list */}
                <div className={'w-15-tutorial'} id={'fake-column'}>
                    reload page
                </div>

                {/* main content */}
                <div className={'tutorial-content contain'}>
                    {/* top */}
                    <div className={'TopOfArticle'}>
                        <div className={'fake'}></div>

                        <div>
                            <h1 className={'text-center active-color vazir-bold'}>
                                {tutorial.title}
                            </h1>
                        </div>

                        <br/>

                        {/* show video */}
                        {tutorial.video && (
                            <div class="h_iframe-aparat_embed_frame">
                                <span className={'aparat-span'}></span>
                                <iframe
                                    src={tutorial.video}
                                    allow="autoplay"
                                    allowFullScreen="true"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                ></iframe>
                            </div>
                        )}
                    </div>

                    <div className={'fake'}></div>

                    {/* next and previous buttons */}
                    <div className={'d-flex justify-content-between'}>
                        {/* /en/tutorial/${category}/${title.slug}/${title.id}/ */}
                        {tutorial.previous_tutorial !== null ?
                            <>
                                <Link
                                    className={'btn active-back activate-back'}
                                    to={`/en/tutorial/${category}/${tutorial.previous_tutorial.slug}/${tutorial.previous_tutorial.id}/`}>
                                    Previous
                                </Link>
                            </>
                            :
                            <>
                                <Link
                                    className={'btn gray-back'}>
                                    Previous
                                </Link>
                            </>
                        }
                        {tutorial.next_tutorial !== null ?
                            <>
                                <Link
                                    className={'btn active-back activate-back'}
                                    to={`/en/tutorial/${category}/${tutorial.next_tutorial.slug}/${tutorial.next_tutorial.id}/`}>
                                    Next
                                </Link>
                            </>
                            :
                            <>
                                <Link
                                    className={'btn gray-back'}>
                                    Next
                                </Link>
                            </>
                        }
                    </div>
                    <br/>

                    {/* main part */}
                    <div className={''}>
                        <div dangerouslySetInnerHTML={{__html: tutorial.content}}></div>
                    </div>

                    <div className={'fake'}></div>

                    {/* next and previous buttons */}
                    <div className={'d-flex justify-content-between'}>
                        {/* /en/tutorial/${category}/${title.slug}/${title.id}/ */}
                        {tutorial.previous_tutorial !== null ?
                            <>
                                <Link
                                    className={'btn active-back activate-back'}
                                    to={`/en/tutorial/${category}/${tutorial.previous_tutorial.slug}/${tutorial.previous_tutorial.id}/`}>
                                    Previous
                                </Link>
                            </>
                            :
                            <>
                                <Link
                                    className={'btn gray-back'}>
                                    Previous
                                </Link>
                            </>
                        }
                        {tutorial.next_tutorial !== null ?
                            <>
                                <Link
                                    className={'btn active-back activate-back'}
                                    to={`/en/tutorial/${category}/${tutorial.next_tutorial.slug}/${tutorial.next_tutorial.id}/`}>
                                    Next
                                </Link>
                            </>
                            :
                            <>
                                <Link
                                    className={'btn gray-back'}>
                                    Next
                                </Link>
                            </>
                        }
                    </div>
                    <br/>

                    <Footer/>

                </div>
            </div>
            <hr/>
        </>
    )
        ;
};

export default Tutorial;
