import '../files/bootstrap/bootstrap.rtl.min.css';
import '../files/css/style.css';
import React, {useEffect, useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import Footer from './component/big/Footer';
import Header from './component/big/Header';

const Layout = () => {
    const [lang, setLang] = useState('');

    useEffect(function () {
        const language = localStorage.getItem('language');
        if (language === null) {
            setLang(navigator.language || navigator.userLanguage);
            localStorage.setItem('language', navigator.language || navigator.userLanguage);
        } else {
            setLang(language);
        }
    }, [lang]);

    return (
        <>
            <Header/>
            <Outlet/>
            <div className={'contain'}>
                <Footer/>
            </div>
        </>
    )
}

export default Layout;