import React from 'react';

const Button = (props) => {
    return (
        <>
            <button className={'btn active-back activate-back vazir'}>{props.text}</button>
        </>
    )
}

export default Button;