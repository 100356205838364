import React from 'react';
import {useState, useEffect} from 'react';
import SiteMap from './SiteMap';
import axios from 'axios';
import {link} from '../../link';
import Config from '../../server/Config';

const Footer = () => {
    const [year, setYear] = useState(2024);
    const [data, setData] = useState([
        {
            index: 0,
            text: '',
        }
    ]);

    useEffect(() => {
        const getLink = async () => {
            const config = Config(true);
            const links = await axios.get(`${link}/set/footer/`, config);
            if (links.status===200) {
                setData(links.data);
            } else {
                console.log(links.data.message);
            }
        }

        getLink();
    }, []);

    useEffect(() => {

        const date = new Date();
        setYear(date.getFullYear());

    }, [year]);

    const enamad = 'https://th.bing.com/th/id/R.ceb329a9b0682f90075a78450c3e1be8?rik=%2bH44%2bToA%2bYBw8g&pid=ImgRaw&r=0';
    const zarinpal = 'https://th.bing.com/th/id/OIP.H0DO-s3wcfyyY0Z_rCV_lgAAAA?rs=1&pid=ImgDetMain';

    return (<>
        <hr/>
        <div className={'footer ltr'}>
            <div className={'fake'}></div>

            {/* social platforms */}
            <div className={'contai'}>
                <h4 className={'text vazir-bold'}>
                    social media:
                </h4>
                <div className={'d-flex align-items-center'}>
                    <a href={'https://www.instagram.com/picodevgit?igsh=a2J3N3J1dDZjM2xk'} target={'_blank'}>
                        <i className={'icon fa fa-instagram activate-color footer-icon white-color'}></i>
                    </a>
                    <a href={'https://t.me/picodevgit'} target={'_blank'}>
                        <i className={'icon fa fa-telegram activate-color footer-icon white-color'}></i>
                    </a>
                    <a href={'https://www.linkedin.com/company/picodevgit/'} target={'_blank'}>
                        <i className={'icon fa fa-linkedin activate-color footer-icon white-color'}></i>
                    </a>
                </div>

            </div>
            <br/>

            {/*  site map  */}
            <div className={'site-map contai'}>

                <h4 className={'text vazir-bold'}>
                    useful links:
                </h4>

                <SiteMap maps={data}/>

                <div className={'fake'}></div>

            </div>

            {/* copyright */}
            <div className={'contai text'}>
                2023-{year} PicoDevGit | All rights reserved. Unauthorized copying or reproduction prohibited &copy;
            </div>
            <div className={'fake'}></div>

        </div>
    </>)
}

export default Footer;