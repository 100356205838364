import React from 'react';
import {Link} from 'react-router-dom';

const SiteMap = (props) => {
    return (
        <>
            <ul className={'map-ul white-color row w-100'}>
                {props.maps.map(m => (
                    <li key={m.index} className={'col-sm-6 col-md-4 col-lg-3 col-xl-2'}>
                        <Link to={m.link} className={'activate-color white-color'}>
                            {m.text}
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default SiteMap;